import * as React from "react";
import { Helmet } from "react-helmet";
import "./global.css";

import * as styles from "./impressum.module.css";

const ImpressumPage = (props) => {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>Impressum</title>
      </Helmet>
      <div className={styles.impressum}>
        <h1>Impressum</h1>
        <h3>Pizzeria Ristorante Villaggio</h3>
        <div className={styles.info}>
          <div>
            <h4>Name</h4>
            <p>Stefan Kopacin</p>
            <h4>Geografische Anschrift</h4>
            <p>Kapfenstein 34, 8353 Kapfenstein</p>
            <h4>Unternehmensgegenstand</h4>
            <p>Restaurant</p>
            <h4>Kontaktdaten</h4>
            <a className={styles.link} href='tel:+436643711199'>
              Telefon: +43(0)664 3711199
            </a>
            <br />
            <br />
            <a className={styles.link} href='mailto:kontakt@villaggio-kapfenstein.at'>
              Email: kontakt@villaggio-kapfenstein.at
            </a>
          </div>
          <div>
            <h4>Mitgliedschaft bei der Wirtschaftskammerorganisation</h4>
            <p>Mitglied der WKÖ</p>
            <h4>Anwendbare Rechtsvorschriften und Zugang dazu</h4>
            <p>Gewerbeordnung:</p>
            <a className={styles.link} href='https://www.ris.bka.gv.at' target='_blank'>
              www.ris.bka.gv.at
            </a>
            <h4>Aufsichtsbehörde</h4>
            <p>Bezirkshauptmannschaft Südoststeiermark</p>
            <h4>Bilder Quellenverweise</h4>
            <a className={styles.link} href='https://icons8.com/' target='_blank'>icons8.com</a>
            <br />
            <br />
            <a className={styles.link} href='https://www.flaticon.com' target='_blank'>www.flaticon.com</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpressumPage;
